<template>
  <div class="d-flex flex-row flex-wrap" id="signIn" style="min-height: 100vh;">
    <div
      class="d-flex flex-column flex-wrap login__left"
      style="flex-grow: 2;"
      :style="{ background: `url(${bg})`, backgroundSize: 'cover' }"
    >
      <div
        class="d-flex flex-row justify-center  flex-grow-1 backdrop mt-9"
      ></div>
    </div>
    <div
      class="d-flex flex-column flex-wrap flex-shrink-0 flex-grow-1 align-content-lg-center justify-center"
    >
      <div class="d-flex flex-row mt-n6 ">
        <h3
          class="pl-4 pt-1 primary--text font-weight-bold font-italic ft text-xl logo-shadow"
        ></h3>
      </div>
      <v-card
        flat
        :loading="isLoading"
        style="min-width: 400px !important;"
        id="loginCard"
        class="rounded-sm ma-3 pl-4 pr-4 pb-6 mt-n12 d-flex flex-column align-content-center"
      >
        <div
          class="signin mx-2 mb-5 mt-4 d-flex flex-column flex-grow-1 align-center"
        >
          <img
            src="@/assets/android-icon-192x192.png"
            style="width: 100px;height: 100px;"
          />
          <div class="font text-md d-flex flex-column align-center pt-3 pl-1">
            <h3>Forgot Password</h3>
            <span class="font font-weight-medium"
              >Having issues logging into your account ?</span
            >
          </div>
        </div>

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(forgotPassword)">
            <ValidationProvider
              name="phoneNumber"
              tag="div"
              class="d-flex flex-column flex-fill flex-wrap"
              rules="required|min:10|max:13"
              v-slot="{ errors }"
            >
              <div class="d-flex flex-column flex-grow-1 flex-shrink-1">
                <v-text-field
                  outlined
                  class=" font mt-2"
                  type="text"
                  label="Enter your phone number"
                  hint="Phone number should be associated with your account"
                  v-model="phoneNumber"
                  :error-messages="errors[0]"
                  :autofocus="true"
                />
              </div>
            </ValidationProvider>

            <div class="d-flex flex-row flex-grow-1 flex-wrap">
              <v-btn
                tag="a"
                small
                @click="$router.push({ name: 'blank.login' })"
                color="primary"
                class="mt-n1 text-sm font font-weight-medium font-size-sm text-capitalize"
                text
                >Back to Login
              </v-btn>
            </div>
            <v-card-actions class="justify-end mt-1 pl-0 pr-0 pb-2">
              <v-btn
                type="submit"
                :disabled="isLoading"
                color="primary"
                block
                :loading="isLoading"
                class="font text-sm text-capitalize"
                >Send Reset Code
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </div>
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="error">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import * as axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, max, min } from "vee-validate/dist/rules";

// Add the required rule
extend("required", {
  ...required,
  message: "This field is required"
});

// Add the email rule
extend("min", {
  ...min,
  message: "Invalid phone number"
});
extend("max", {
  ...max,
  message: "Invalid phone number"
});

export default {
  name: "Login",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      showSnackBar: false,
      bg: require("@/assets/station.jpg"),
      phoneNumber: "",
      isLoading: false,
      load: false,
      timeout: 4000,
      message: "",
      closeSnackBar: false,
      error: "error",
      forgotClicked: false,
      loader: false,
      show: false,
      snackbar: false
    };
  },
  methods: {
    forgotPassword() {
      this.isLoading = true;
      axios({
        method: "post",
        url: `${process.env.VUE_APP_BASE}/auth/forgot-password`,
        data: { phoneNumber: this.phoneNumber }
      })
        .then(() => {
          this.error = "success";
          this.isLoading = false;
          this.showSnackBar = true;
          this.message =
            "Password reset code successfully sent to your phone number";
          setTimeout(() => {
            this.$router.replace({
              name: "blank.login"
            });
          }, 2000);
        })
        .catch(err => {
          this.isLoading = false;
          this.showSnackBar = true;
          this.error = "error";
          this.message = Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message?.join(",")
            : err?.response?.data?.message;
        });
    }
  }
};
</script>
<style scoped></style>
